import { onAuthStateChanged } from 'firebase/auth';
import { createRouter, createWebHashHistory } from 'vue-router';
import { auth } from './firebase/auth';

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            component: () => import('./pages/Home.vue'),
        },
        {
            path: '/login',
            component: () => import('./pages/Login.vue'),
        },
        {
            path: '/newquery',
            component: () => import('./pages/NewQuery.vue'),
        },
        {
            path: '/query',
            component: () => import('./pages/Query.vue'),
        },
        {
            path: '/tags',
            component: () => import('./pages/ManageTags.vue'),
        },
        {
            path: '/card/:id',
            component: () => import('./pages/UpdateCard.vue'),
        },
        {
            path: '/card',
            component: () => import('./pages/UpdateCard.vue'),
        }
    ]
});

export default router;

onAuthStateChanged(auth, (currentUser) => {
    if (!currentUser) {
        router.replace('/login');
    }
})